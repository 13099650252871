$(document).ready(function () {
	$("nav").pin();
	
	$(".menu").click(function(){
	$(this).toggleClass("abierto");
		$("nav ul").slideToggle(100)
	});
	$(".btpoliticas").click(function(){
		$(".overlayPoliticas").fadeIn()
	});
	$(".btterminos").click(function(){
		$(".overlayTerminos").fadeIn()
	});
	$(".cerrarOverlay").click(function(){
		$(".overlayPoliticas").fadeOut()
		$(".overlayTerminos").fadeOut()
	});
	
	$('a')
    .on('touchstart', function(){
        isScrolling = false;
    })
    .on('touchmove', function(e){
        isScrolling = true;
    })
    .on('touchend', function(e){
        if( !isScrolling )
        {
            window.location = $(this).attr('href');
        }
    });
    
    $('.mapa')
	.click(function(){
			$(this).find('iframe').css('pointer-events', 'auto')})
	.mouseleave(function(){
			$(this).find('iframe').css('pointer-events', 'none')});
});

